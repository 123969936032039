<template>
    <div id="app">
        <div>
            <div>
                <h1>Welcome to TSMC-pet</h1>
                <p>Your journey to success and rewards starts here</p>
            </div>
        </div>

    </div>
</template>
